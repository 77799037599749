(function($) {
    'use strict';

     /**
     * Add a 'Back' link to sub menus
     */


    // $('.sub-menu:not(.sub-menu > .menu__item > .sub-menu)').each(function() {
    //     $(this).prepend('<li class="menu__item menu__item--back">Back</li>');
    // });

    $('.menu__item--has-children').append('<span class="menu__item--toggle"></span>');


    /**
     * Register click events on sub navigation toggles
     */

    $('.menu__item--has-children > a').on('click', function(e) {
        e.preventDefault();
        $(this).parent().toggleClass('is-active');
        $(this).child('.menu__item--toggle').toggleClass('is-active');
    });

    

    $('.s-page-wrap').on('click', function () {
        if ($('body').hasClass("navigation--is-active")) {
            $('body').removeClass('navigation--is-active');    
        }
    });

    $('.menu__item--toggle').on('click', function () {
        $(this).toggleClass('toggle-active');
    });


    $('.c-offcanvas__close').on('click', function(e){
        e.preventDefault();
        $('body').removeClass('navigation--is-active');
    });

    $('.c-row--booking-bar .c-booking-bar').prepend('<div class="c-booking-bar--dropdown"></div>');

    $('.c-booking-bar--dropdown').on('click', function(e){
        e.preventDefault();
        $('body').toggleClass('booking-bar--is-active');
    });
    $('.js-flickity').trigger('change.flickity');

    $(".datepicker").attr('autocomplete', 'off');

    $("label[for='property-type']").text('Area');    
    //$("label[for='property-bedrooms']").text('Accommodates');
    


    $('.js-arrival').attr('placeholder','Check-In');
    $('.js-departure').attr('placeholder','Check-Out');


 
})(jQuery);